<template>
    <div class="person-view-container">
        <div class="person-list-item" v-for="person in concernedPersons" :key="person.patientName" @click="clickPerson(person.patientName, person.patientNickname)">
            <div class="person-info">
                <div class="person-icon"><i class="el-icon-s-custom"></i></div>
                <div class="patient-name">{{person.patientNickname != "" ? person.patientNickname + " (" + person.patientName + ")" : person.patientName}}</div>
            </div>
            <div class="next-icon">
                <div class="arrow-icon"><i class="el-icon-arrow-right"></i></div>
            </div>
        </div>
        <div class="person-study-view" v-if="studyViewVisible">
            <!-- <StudyListView :patientName="patientName" v-on:closeView="closeStudyView" /> -->
        </div>
        <div class="add-hint">查找检查，在检查信息页底部，点击【收藏此检查】，添加关心的人</div>
        <router-view></router-view>
    </div>
</template>

<script>
// import StudyListView from './StudyListView.vue'
import {MessageProxy} from '../../utils/MessageProxy'
import { store } from '../../utils/store'

export default {
    name: 'ConcernedPersonListView',
    components: {
        // StudyListView
    },
    data() {
        return {
            concernedPersons: [],
            patientName: "",
            studyViewVisible: false
        }
    },
    created() {
        MessageProxy.getConcernedPatientList(store.userId).then((result)=>{
            if (true == result.hasError) {
                this.$message.error(result.errorText);
                return;
            }
            if (result.data != null && result.data.code != '200') {
                this.$message.error(result.data.message);
                return;
            }
            this.concernedPersons = result.data.data;
        })

        // this.concernedPersons.push({patientName: "王强", nickname: "爸爸"});
        // this.concernedPersons.push({patientName: "李丽", nickname: "小姨"});
        // this.concernedPersons.push({patientName: "刘宏", nickname: "朋友"});
    },
    methods: {
        clickPerson(patientName, nickname) {
            // this.patientName = nickname + " (" + patientName + ")";
            // this.$message.info(this.patientName);
            // this.studyViewVisible = true;
            this.$router.push({name: 'studylist', query:{concernedPersonName: patientName, concernedPersonNickname: nickname}});
        },
        closeStudyView() {
            this.studyViewVisible = false;
        }
    }
}
</script>

<style scoped>
    .person-view-container {
        position: relative;
        height: 100%;
        overflow-y: auto;
    }

    .person-view-container .add-hint {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        color: darkorange;
        font-size: 3vmin;
        padding-bottom: 1vmin;
        z-index: 0;
    }

    .person-list-item {
        position: relative;
        background-color: #fff;
        color: #222;
        /* border-top: 1px solid #c0c0c0;
        border-bottom: 1px solid #c0c0c0; */
        margin: 1vmin 0 2vmin 0;
        padding: 3vmin;
        text-align: left;
        font-size: 4vmin;

        display: flex;
        align-items: stretch;
    }

    .person-list-item:active {
        background-color: #eaeaea;
    }

    .person-list-item .person-info {
        flex-grow: 1;
    }
    .person-list-item .next-icon {
        font-size: 4vmin;
        color: #ccc;

        display: flex;
        justify-content: center;
        align-items: center;
    }
    .person-list-item .person-icon {
        font-size: 5vmin;
        display: inline-block;
    }
    .person-list-item .patient-name {
        font-size: 4vmin;
        display: inline-block;
        margin-left: 5vmin;
    }

    .person-study-view {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #f6f6f6;
        z-index: 2;
    }
</style>
