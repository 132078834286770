import { render, staticRenderFns } from "./PatientListView.vue?vue&type=template&id=52bcc884&scoped=true&"
import script from "./PatientListView.vue?vue&type=script&lang=js&"
export * from "./PatientListView.vue?vue&type=script&lang=js&"
import style0 from "./PatientListView.vue?vue&type=style&index=0&id=52bcc884&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52bcc884",
  null
  
)

export default component.exports