<template>
    <div class="patient-view-container">
        <div class="title-container">
            <div class="back-icon" @click="closeView()"><i class="el-icon-back"></i><span class="view-name">关心的人</span></div>
        </div>
        <div class="patient-list-item" v-for="person in concernedPersons" :key="person.patientName">
            <div class="patient-info">
                <div class="patient-icon"><i class="el-icon-s-custom"></i></div>
                <div class="patient-name">{{person.patientNickname != "" ? person.patientNickname + " (" + person.patientName + ")" : person.patientName}}</div>
            </div>
            <div class="tool-icon" @click="editPatientInfo(person.patientName, person.patientNickname)"><i class="el-icon-edit"></i></div>
            <div class="tool-icon" @click="deleteConcernedPatient(person.patientName, person.patientNickname)"><i class="el-icon-delete"></i></div>
        </div>
    </div>
</template>

<script>
import {MessageProxy} from '../../utils/MessageProxy'
import { store } from '../../utils/store'

export default {
    name: 'PatientListView',
    data() {
        return {
            concernedPersons: []
        }
    },
    created() {
        this.getData();
        // this.concernedPersons.push({patientName: "王强", nickname: "爸爸"});
        // this.concernedPersons.push({patientName: "李丽", nickname: "小姨"});
        // this.concernedPersons.push({patientName: "刘宏", nickname: "朋友"});
    },
    methods: {
        closeView() {
            this.$emit("closeView");
            this.$router.go(-1);
        },
        editPatientInfo(patientName, nickname) {
            this.$prompt(`请输入 ${patientName} 的备注名称`, '修改备注', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPlaceholder: nickname,
                inputValidator: (value)=>{
                    if(null == value || value.trim().length < 1){
                        return '请输入备注名';
                    }
                },
            }).then(({ value }) => {
                MessageProxy.updatePatientNickname(store.userId, patientName, value).then((result)=>{
                    if (true == result.hasError) {
                        this.$message.error(result.errorText);
                        return;
                    }
                    if (result.data != null && result.data.code != '200') {
                        this.$message.error(result.data.message);
                        return;
                    }
                    this.$message.success("修改成功。");

                    //刷新
                    this.getData();
                })
            }).catch(() => {
            });
        },
        deleteConcernedPatient(patientName, nickname) {
            this.$confirm(`确定不再关注“${nickname} (${patientName})”吗？`, '取消关注', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                MessageProxy.deleteConcernedPatient(store.userId, patientName).then((result)=>{
                    if (true == result.hasError) {
                        this.$message.error(result.errorText);
                        return;
                    }
                    if (result.data != null && result.data.code != '200') {
                        this.$message.error(result.data.message);
                        return;
                    }
                    this.$message.success("取消成功。");

                    //刷新
                    this.getData();
                })
            }).catch(() => {
            });
        },
        getData() {
            MessageProxy.getConcernedPatientList(store.userId).then((result)=>{
                if (true == result.hasError) {
                    this.$message.error(result.errorText);
                    return;
                }
                if (result.data != null && result.data.code != '200') {
                    this.$message.error(result.data.message);
                    return;
                }
                this.concernedPersons = result.data.data;
            })
        }
    }
}
</script>

<style scoped>
    .patient-view-container {
        height: 100%;
        overflow-y: auto;

        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #f6f6f6;
        z-index: 2;
    }
    .title-container {
        height: 12vmin;

        display: flex;
        align-items: stretch;
    }
    .title-container .back-icon {
        font-size: 6vmin;
        padding: 0 3vmin;

        display: flex;
        align-items: center;
    }
    .title-container .view-name {
        font-size: 4vmin;
        margin-left: 3vmin;
    }
    .title-container .back-icon:active {
        background-color: #eaeaea;
    }

    .patient-list-item {
        position: relative;
        background-color: #fff;
        color: #222;
        margin: 1vmin 0 2vmin 0;
        padding-left: 3vmin;
        text-align: left;
        font-size: 4vmin;
        height: 12vmin;

        display: flex;
        align-items: stretch;
    }

    .patient-list-item .patient-info {
        flex-grow: 1;

        display: flex;
        align-items: center;
    }
    .patient-list-item .tool-icon {
        font-size: 6vmin;
        color: #333;
        padding: 0 8vmin 0 0;

        display: flex;
        justify-content: center;
        align-items: center;
    }
    .patient-list-item .tool-icon:active {
        background-color: #eaeaea;
    }
    .patient-list-item .patient-icon {
        font-size: 5vmin;
        display: inline-block;
    }
    .patient-list-item .patient-name {
        font-size: 4vmin;
        display: inline-block;
        margin-left: 5vmin;
    }
</style>
